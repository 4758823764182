import React, {ChangeEvent, useState, useRef, useEffect} from 'react';
import {Box, Button, IconButton, Input} from "@mui/material";
import useFilesStore from "../store";
import Webcam from "react-webcam";
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import {Panorama} from "@mui/icons-material";
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import mainPageImage from '../resources/mainPage.png'
import mainPageBtn from '../resources/btn.png'

interface FileUploadProps {
    updateLoading: (isLoading: boolean) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({updateLoading}) => {
        const [selectedFile, setSelectedFile] = useState<File | null>(null);
        const fileStore = useFilesStore()
        const webcamRef = useRef(null);
        const [isWebcamOn, setWebcamOn] = useState<boolean>(false); // Add this line
        const [error, setError] = useState<string>(''); // Add this line
        const [response, setResponse] = useState<any>(); // Add this line
        const success = fileStore.success
        const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
            setResponse('hii')
            setError('hell')
            if (event.target.files && event.target.files[0]) {
                setSelectedFile(event.target.files[0]);
                handleUpload(event.target.files[0]).then();
                event.target.value = '';
            }
        };

        function getMimeType(base64String: string): string | null {
            const mimeTypeMatch = base64String.match(/^data:(image\/(\w+));base64,/);
            return mimeTypeMatch ? mimeTypeMatch[2] : null;
        }

        const fetchImage = async (image: string) => {
            const base64Image = image.split(',')[1]
            const mimeType = getMimeType(image);

            try {

                updateLoading(true);
                setError('')
                const res = await fetch('https://9jw8tj713f.execute-api.us-east-1.amazonaws.com/prod/items', {
                    method: 'POST',
                    headers: {
                        'Cache-Control': 'no-cache'
                    },
                    body: JSON.stringify({
                        image: base64Image,
                        mimeType
                    }),
                });

                if (!res.ok) {
                    fileStore.setError('ERROR. STATUS: ' + res['status'])

                }
                console.log(res)
                const data = await res.json();
                fileStore.setSuccess(true)
                fileStore.setFiles(data as string[])

                updateLoading(false);

            } catch (error: any) {
                fileStore.setError('ERROR: ' + error.toString())
                fileStore.setFiles([])
                updateLoading(false);
                setError(error)
                console.error('Error:', error);
            }
        }
        const handleUpload = async (file: File) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                const base64Image = reader.result as string;
                await fetchImage(base64Image)

            };
        }
        const capture = React.useCallback(
            () => {
                // @ts-ignore
                const imageSrc = webcamRef.current.getScreenshot();
                fetchImage(imageSrc).then();

            },
            [webcamRef]
        );

        function showMainPage() {
            return !success && !isWebcamOn
        }

        return (
            <Box
            >

                {
                    showMainPage() && (

                        <Box
                            mt={4}
                            width={'100%'}
                            height={'100%'}
                            display={'block'}
                            justifyContent={'center'}
                            textAlign={'center'}
                        >
                            <img width={'95%'} height={'100%'} src={mainPageImage}/>
                            <Box
                                display={'flex'}
                                justifyContent={'center'}

                            >
                                <Button sx={{
                                    backgroundColor: '#dc99c4',
                                    color: 'white',
                                    borderRadius: 15,
                                    px: 2,
                                    my: 3,
                                    fontSize: 18
                                }} onClick={() => setWebcamOn(!isWebcamOn)}>
                                    צלם אותי
                                </Button>
                            </Box>
                            {/*<img width={'100%'} height={'100%'} src={mainPageBtn}/>*/}

                        </Box>
                    )
                }
                {isWebcamOn && (
                    <Box
                        style={{
                            objectFit: 'cover',
                        }}
                        height={'100vh'}
                        justifyContent={'center'}
                        p={0}
                        m={0}
                        // sx={{backgroundColor: 'red'}}
                    >
                        <Webcam
                            style={{
                                objectFit: 'cover',
                            }}
                            height={'100%'}
                            width={'100%'}
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            mirrored={true}
                            videoConstraints={{
                                facingMode: "user"
                            }}
                        />

                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '20px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 'fit-content'
                            }}
                            paddingTop={2}
                            paddingBottom={2}
                        >
                            <IconButton
                                aria-label="next"
                                onClick={capture}
                                sx={{color: 'white'}}
                            >
                                <PanoramaFishEyeIcon color={'inherit'} sx={{fontSize: '70px'}}/>
                            </IconButton>
                        </Box>
                        <Box

                            paddingTop={2}
                            paddingBottom={2}
                            sx={{position: 'absolute', bottom: '20px', left: '20px'}}
                        >
                            <Input
                                inputProps={{accept: 'image/*'}}
                                type="file"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                                id="upload-button"
                            />
                            <label htmlFor="upload-button">

                                <IconButton
                                    component={'span'}
                                    aria-label="next"
                                    sx={{color: 'white'}}
                                >
                                    <CollectionsRoundedIcon color={'inherit'} sx={{fontSize: '40px'}}/>
                                </IconButton>
                            </label>

                        </Box>
                    </Box>
                )}


                <Box>

                </Box>
            </Box>
        )
    }
;
export default FileUpload;
