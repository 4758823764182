import React, {useContext, useEffect, useRef, useState} from 'react';
import Match from "../models/match";
import {
    Box,
    Grid,
    Modal,
    IconButton,
    useTheme,
    Typography,
    ImageList,
    ImageListItem,
    CircularProgress
} from "@mui/material";
import useFilesStore from "../store";
import {useMediaQuery} from '@mui/material';
import {downloadFile} from "../utils/fileDownloader";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import {imagesListMock, imagesListMock2} from "../mocks/imagesListMock";
import {createBrowserHistory, Update} from "history";
import {useLocation} from "react-router-dom";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // you can choose other effects
import 'react-lazy-load-image-component/src/effects/black-and-white.css'; // you can choose other effects
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import shareImage from "../resources/share.png";

interface ImagesDisplayProps {
    matchesData: Match[];
}

const imagePath = (str: string, highQuality = false, customQuality = '') => {
    if (!str) return ''

    const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT;
    const parts = str.split('_');
    const firstPart = parts[0];
    const suffix = parts[1].split('.').pop(); // This will give you the file extension
    const result = `${firstPart}.${suffix}`;
    const quality = highQuality ? 'images' : 'low-res-images';
    return cloudFrontUrl + `/${customQuality ? customQuality : quality}/R-A-wedding/${result}`;


}

const imageName = (str: string) => {
    const parts = str.split('_');
    const firstPart = parts[0];
    const result = `${firstPart}.jpg`;
    return result
}

const Images = () => {

    const scrollPosition = useRef(0);

    const isMobile = useMediaQuery('(max-width:600px)');
    const viewportWidth = window.innerWidth;
    const filesStore = useFilesStore()
    const matches = filesStore.files
    const error = filesStore.error
    const success = filesStore.success
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const maxImageIndex = matches.length - 1;

    const aspectRatio = '75%'; // Default aspect ratio, adjust as needed

    const handleOpen = (imagePath: string, index: number) => {
        setSelectedImage(imagePath);
        setCurrentImageIndex(index);
        setOpen(true);
    };

    const handleNext = () => {
        const nextIndex = currentImageIndex + 1;
        if (nextIndex <= maxImageIndex) {
            setSelectedImage(matches[nextIndex]);
            setCurrentImageIndex(nextIndex);
        }
    };

    const handlePrev = () => {
        const prevIndex = currentImageIndex - 1;
        if (prevIndex >= 0) {
            setSelectedImage(matches[prevIndex]);
            setCurrentImageIndex(prevIndex);
        }
    };
    const handleDownload = (imagePath: string, fileName: string) => {
        setIsLoadingDownload(true);
        downloadFile(imagePath, fileName)
            .then(() => {
                setIsLoadingDownload(false);
            })
            .catch((e: any) => {
                console.log(e);
                setIsLoadingDownload(false);
            });
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            scrollPosition.current = window.scrollY;

            setOpen(false);
            event.preventDefault();

            window.scrollTo(0, scrollPosition.current);

        }
        window.addEventListener('popstate', handlePopState);

    }, []);

    return (
        <Box dir={'rtl'}>

            {error && <Typography color={'error'}>שגיאה: {error}</Typography>}

            {success && (

                <Box>

                    <Box
                        mt={2}
                        mb={2}
                        width={'100%'}
                        height={'100%'}
                        display={'block'}
                        justifyContent={'center'}
                        textAlign={'center'}
                    >
                        <img width={'95%'} height={'100%'} src={shareImage}/>

                    </Box>

                    <Box display={'flex'} pb={1}>
                        <Typography fontWeight={"bold"} fontSize={18} textAlign={'start'}>
                            {matches?.length} תמונות שלך נמצאו
                        </Typography>

                    </Box>

                    <ImageList variant="masonry" cols={isMobile ? 2 : 3} gap={4}
                    >
                        {matches.map((image: string, index: number) => (
                            <Box style={{position: 'relative'}} borderRadius={3}
                                 onClick={() => handleOpen(image, index)}
                            >
                                <ImageListItem
                                    style={{
                                        position: 'relative',
                                        paddingBottom: '60%', // This is the aspect ratio
                                        height: 0,
                                        overflow: 'hidden',
                                    }}
                                    key={index}>
                                    <LazyLoadImage
                                        placeholderSrc={imagePath(image, false, 'ultra-low-res-images')}
                                        wrapperProps={{
                                            style: {
                                                transitionDelay: "1s",
                                                height: '100%',
                                                width: '100%'
                                            },
                                        }}
                                        effect="black-and-white"
                                        threshold={1000}
                                        srcSet={`${imagePath(image)}`}
                                        src={`${imagePath(image)}`}
                                        loading="lazy"
                                        width={'100%'}

                                        // style={{
                                        //     borderRadius: '2%',
                                        // }}
                                    />
                                </ImageListItem>


                            </Box>
                        ))}


                    </ImageList>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isMobile ? '90%' : '23%',
                            outline: 'none'
                        }}>
                            <IconButton
                                sx={{
                                    '&:hover': {
                                        color: 'white',
                                    },
                                    zIndex: 1

                                }}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                }}
                                disabled={isLoadingDownload}
                                onClick={() => handleDownload(imagePath(selectedImage, true), imageName(selectedImage))}>
                                { isLoadingDownload ?  <CircularProgress size={"1.5rem"}/> : <DownloadRoundedIcon/>}
                            </IconButton>
                            <IconButton
                                sx={{
                                    '&:hover': {
                                        color: 'white',
                                    },
                                    zIndex: 1

                                }}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                                onClick={handleClose}>
                                <CloseRoundedIcon/>
                            </IconButton>

                            <IconButton
                                aria-label="previous"
                                onClick={handlePrev}
                                sx={{
                                    position: 'absolute',
                                    left: 8,
                                    top: '50%',
                                    color: 'white',
                                    zIndex: 1
                                }}
                                disabled={currentImageIndex === 0}
                            >
                                <ArrowBackIosRoundedIcon fontSize={'large'}/>
                            </IconButton>
                            <IconButton
                                aria-label="next"
                                onClick={handleNext}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: '50%',
                                    color: 'white',
                                    zIndex: 1
                                }}
                                disabled={currentImageIndex === maxImageIndex}
                            >
                                <ArrowForwardIosRoundedIcon fontSize={'large'}/>
                            </IconButton>
                            <LazyLoadImage placeholderSrc={imagePath(selectedImage, false, 'ultra-low-res-images')}
                                           src={imagePath(selectedImage, false)} alt="Selected" effect="blur"
                                           style={{width: '100%', height: '100%'}}
                                           wrapperProps={{
                                               style: {
                                                   transitionDelay: "1s",
                                                   height: '100%',
                                                   width: '100%',
                                               },
                                           }}
                            />

                        </Box>
                    </Modal>
                </Box>
            )}

        </Box>
    );
};
export default Images;
