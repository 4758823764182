import { create } from 'zustand';

interface FilesStoreState {
    files: string[];
    error: string;
    success: boolean;
    setSuccess: (success: boolean) => void;
    setFiles: (payload: string[]) => void;
    setError: (error: string) => void;
}

const useFilesStore = create<FilesStoreState>((set) => ({
    files: [],
    error: '',
    setFiles: (payload: string[]) => set((state) => ({ ...state, files: payload })),
    setError: (error: string) => set((state) => ({ ...state, error: error })),
    success: false,
    setSuccess: (success: boolean) => set((state) => ({ ...state, success: success })),
}));

export default useFilesStore;
