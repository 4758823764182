export const downloadFile = async (fileUrl: string, fileName: string): Promise<void> => {

    const baseUrl = 'https://o2n25xzbb4.execute-api.us-east-1.amazonaws.com/prod/items';
    const url = new URL(baseUrl);
    url.searchParams.append('fileName', fileName);
    const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/octet-stream'
        }
    });

    if (response.ok) {
        const base64Data = await response.text(); // get the base64 string
        const byteCharacters = atob(base64Data); // decode base64 string
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]); // create blob from byte array

        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
    }
    else{
        throw new Error(`Failed to download file: ${JSON.stringify(response)}`);
    }
};
