import React, {useEffect, useState} from 'react';
import './App.css';
import UniqueFaces from './components/uniqueFaces';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Images from "./components/images";
import {Box, CircularProgress, Container} from "@mui/material";
import Match from "./models/match";
import FileUpload from "./components/fileUpload";

function App() {
    const [matches, setMatches] = useState<Match[]>([]);
    const [loading, setLoading] = useState(true);
    const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT;
    const dataPrefix = 'data/R-A-wedding';
    useEffect(() => {
        const fetchData = async () => {
            try {
                const matchesResponse = await fetch(`${cloudFrontUrl}/${dataPrefix}/matches.json`);
                const matchesData = await matchesResponse.json();
                setMatches(matchesData);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [cloudFrontUrl, dataPrefix]);

    if (loading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress/>
                </Box>
            </Container>
        );
    }

    return (
        <Box>
            <header className="App-header">
                <FileUpload updateLoading={setLoading}></FileUpload>
            </header>


            <Container>
                <div className="App">
                    <Images/>
                    {/*<Routes>*/}
                    {/*    {<Route path="/images" element={<Images/>}/>}*/}

                    {/*</Routes>*/}
                </div>
            </Container>

        </Box>

    );

}

export default App;
